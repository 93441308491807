import React, { useState } from "react";
import Layout from "layout";
import { navigate } from "gatsby";

import Hero from "common/hero";
import Seo from "common/seo";
import { Row, Col } from "react-grid-system";

import heroImageRef from "images/sign-up/hero.png";

import "./sign-up.scss";
import { Link } from "gatsby";
import { Controller, useForm } from "react-hook-form";
const axios = require("axios").default;
const FORM_GATEWAY_URL = process.env.GATSBY_SIGN_UP_API;

console.log(process.env);

function SignupPage({ pageContext }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const handleSubmitForm = (data) => {
    console.log("data: ", data);
    setIsSubmitting(true);
    axios
      .post(FORM_GATEWAY_URL, JSON.stringify(data))
      .then(function (response) {
        setIsSubmitting(false);
        //setTimeout(() => {
        navigate("/sign-up/thank-you");
        //}, 100);
      })
      .catch(function (error) {
        setIsSubmitting(false);
        // setOpen(true);
        console.log(error);
      });
  };
  function validateNumber(e) {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode == 8
      )
    ) {
      e.preventDefault();
    }
  }
  return (
    <Layout
      pageClass="page-signup"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Email Sign-up"
        pageDescription="Register as a skin care professional"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container colored-boxes">
          <Row>
            <Col sm={12}>
              <div className="form-holder">
                <span className="req">All fields are required.</span>
                <form className="form">
                  <div
                    className={`form-group ${errors.fname ? "error-show" : ""}`}
                  >
                    <input
                      name="fname"
                      {...register("fname", {
                        required: true,
                      })}
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                    />
                    <span className="error">Please enter valid first name</span>
                  </div>
                  <div
                    className={`form-group ${errors.lname ? "error-show" : ""}`}
                  >
                    <input
                      name="lname"
                      type="text"
                      {...register("lname", {
                        required: true,
                      })}
                      placeholder="Last Name"
                      className="form-control"
                    />
                    <span className="error">Please enter valid last name</span>
                  </div>
                  <div
                    className={`form-group ${
                      errors.number ? "error-show" : ""
                    }`}
                  >
                    <input
                      name="number"
                      type="text"
                      maxLength={10}
                      onKeyDown={validateNumber}
                      {...register("number", {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                      })}
                      placeholder="Individual National Provider Number (NPI)"
                      className="form-control"
                    />
                    <span className="error">
                      Please enter valid NPI 10 digit
                    </span>
                  </div>
                  <div
                    className={`form-group ${errors.email ? "error-show" : ""}`}
                  >
                    <input
                      name="email"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter a valid email",
                        },
                      })}
                      type="email"
                      placeholder="Email"
                      className="form-control"
                    />
                    <span className="error">
                      Please enter a correctly formatted email address
                    </span>
                  </div>
                  <div
                    className={`form-group ${
                      errors.confirmEmail ? "error-show" : ""
                    }`}
                  >
                    <input
                      {...register("confirmEmail", {
                        required: true,
                        validate: (val) => {
                          if (watch("email") != val) {
                            return "Your Email do not match";
                          }
                        },
                      })}
                      name="confirmEmail"
                      type="email"
                      placeholder="Confirm Email"
                      className="form-control"
                    />
                    <span className="error">Please confirm email address</span>
                  </div>
                  <div
                    className={`form-group form-check ${
                      errors.agreeTerms ? "error-show" : ""
                    }`}
                  >
                    <Controller
                      name="agreeTerms"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <input type="checkbox" {...field} />
                      )}
                    />
                    <span>
                      I am a healthcare professional and would like to receive
                      information from Beiersdorf Inc. about products and
                      educational materials. By selecting Sign Up, I agree to
                      the{" "}
                      <Link to="/termsandconditions">Terms and Conditions</Link>{" "}
                      in accordance with your{" "}
                      <Link to="/privacypolicy">Privacy Policy</Link>.
                    </span>
                    <span>
                      I understand that I may choose to no longer receive
                      further communications from BeiersdorfMD.com by following
                      the unsubscribe instructions in the communications I
                      receive.
                    </span>
                    <p className="error">Please check the box to continue</p>
                  </div>
                  <div className="btn-wrap">
                    <button
                      className="button"
                      onClick={handleSubmit(handleSubmitForm)}
                      disabled={isSubmitting ? true : false}
                      style={{ cursor: "pointer" }}
                    >
                      {isSubmitting ? "Submitting..." : "Sign up"}
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </Layout>
  );
}
export default SignupPage;

const MyHero = () => (
  <Hero className="inner-hero" bgImagePath={heroImageRef}>
    <div className="inner-hero-content">
      <h1>
        Register to become a <br className="show-desktop" />
        Beiersdorf Insider
      </h1>
      <p className="hero-reg-text">
        <span>
          Want to hear about the latest innovations and scientific breakthroughs
          in dermatological care?
        </span>
        <span>
          Sign up to receive exclusive news, educational materials, and clinical
          results from Eucerin<sup>&reg;</sup> and Aquaphor<sup>&reg;</sup>.
        </span>
      </p>
    </div>
  </Hero>
);
